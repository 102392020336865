import api from '../library/axios';

export default {
  dashboardAnalytic({
    workspace_id, date_type, start_date, end_date,
  }) {
    return api({
      method: 'get',
      url: `/api/analytics/dashboard/${workspace_id}`,
      params: {
        date_type,
        start_date,
        end_date,
      },
    });
  },
  workspaceStats({
    workspace_id,
  }) {
    return api({
      method: 'get',
      url: `/api/analytics/stats/${workspace_id}`,
    });
  },
  chatbotAnalytics({
    workspace_id,
  }) {
    return api({
      method: 'get',
      url: `/api/analytic-dashboard/${workspace_id}`,
    });
  },
  channelSummary({
    workspace_id,
    start_date,
    end_date,
  }) {
    return api({
      method: 'get',
      url: `/api/analytics/session/${workspace_id}/summary`,
      params: {
        start_date,
        end_date,
      },
    });
  },
};
